@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
.home_page {
  background: url("./assets/image/home.jpeg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.montserrat-family{
  font-family: "Montserrat", sans-serif;
}
