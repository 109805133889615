// @import url("https://fonts.googleapis.com/css?family=Quicksand:400,700&display=swap");

body {
  font-family: "Quicksand", sans-serif;
}

.card:hover img {
  transform: scale(1.1);
}

.card:hover .content {
  visibility: visible;
  opacity: 1;
  bottom: -10px;
  transform: scale(1.1);
}

.card:hover .cover {
  visibility: visible;
  opacity: 1;
  bottom: -10px;
  transform: scale(1.1);
}

.card:hover .shadow {
  width: 290px;
  visibility: visible;
  opacity: 1;
  bottom: -20px;
  transform: scale(1.1) translate(-45%);
}

.card:active img {
  transform: scale(0.9);
}

.card:active .content {
  opacity: 0;
  transform: scale(0.9);
}

.card:active .cover {
  opacity: 0.3;
  bottom: 10px;
  transform: scale(0.9);
}

.card:active .shadow {
  opacity: 0.3;
  bottom: -20px;
  transform: scale(0.9) translate(-45%);
}
